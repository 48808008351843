<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Reporting Pusdafil'" />

    <div>
      <h4 class="pb-4">Excel Input (Bulk)</h4>

      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="pusdafil.file"
        @beforedelete="deleteFile($event)"
        ref="excelFile"
      ></VueFileAgent>

      <div class="py-4" />

      <v-select
        v-model="pusdafil.table"
        :items="tableOptions"
        item-text="label"
        item-value="value"
        :rules="[(v) => !!v || 'Mohon pilih tabel input']"
        label="Tabel Input"
        color="primary"
        outlined
        dense
        required
      ></v-select>

      <button-form
        :submit="submitExcel"
        :buttonText="'Upload'"
        :loading="loading"
        class="pt-3"
      />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error
      :dialog="showErrDialog"
      :closeDialog="closeErrDialog"
      :errorMessage="errorMsg"
    />
  </v-card>
</template>

<script>
import store from "../../store/index";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    ButtonForm,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      steps: 1,
      showSuccessDialog: false,
      showErrDialog: false,
      errorMsg: "",
      loading: false,
      pusdafil: {
        table: "",
        file: "",
      },
      tableOptions: [
        {
          label: "Pengguna",
          value: "pengguna",
        },
        {
          label: "Borrower",
          value: "borrower",
        },
        {
          label: "Lender",
          value: "lender",
        },
        {
          label: "Pengajuan",
          value: "pengajuan",
        },
        {
          label: "Pemberian",
          value: "pemberian",
        },
        {
          label: "Transaksi",
          value: "transaksi",
        },
        {
          label: "Pembayaran",
          value: "pembayaran",
        },
      ],
    };
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    deleteFile(fileRecord) {
      this.$refs.excelFile.deleteFileRecord(fileRecord);
      this.pusdafil.file = "";
    },
    async submitExcel() {
      if (this.pusdafil.file) {
        this.loading = true;
        let payload = new FormData();
        payload.append("table", this.pusdafil.table);
        payload.append("json", "bulk");
        payload.append("file", this.pusdafil.file.file);
        await store.dispatch("office/addExcel", payload).then((r) => {
          if (r.status === 200) {
            this.showSuccessDialog = !this.showSuccessDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
            if (r.data.length === 0) {
              this.errorMsg =
                "Mohon cek kembali field pada excel, pastikan ada data yang akan diisi ke tabel yang dipilih";
            } else {
              this.errorMsg = "Data sudah terinput di database";
            }
          }
        });
      } else {
        this.showErrDialog = true;
      }
    },
  },
};
</script>
